import React from "react"
import SEO from "../components/seo"
import TopBanner from "../components/common/top-banner/top-banner"
import { graphql } from "gatsby"
import ExtendedContent from "../components/extendedContent"
import { setTopColors, toggleSubmenu } from "../state/app"
import { connect } from "react-redux"
import { saveUrlsToLocalStorage } from "../components/common/type-to-url-match/type-to-url-match"


class AboutUsPage extends React.Component {
  componentDidMount() {
    const { dispatch, pageContext, data } = this.props
    const entries = data.entries?.nodes?.[0] || ""
    const topColors = entries.archeeTopKolory
    dispatch(setTopColors(topColors))
    dispatch(toggleSubmenu(false))
    saveUrlsToLocalStorage(pageContext)
  }
  render() {
    const { data } = this.props
    const entries = data.entries?.nodes?.[0] || ""
    return (
      <>
        <TopBanner banner={entries.archeeBaner} topColors={entries.archeeTopKolory}/>
        <ExtendedContent data={entries.archeeTrescPodstrony}/>
      </>
    )
  }
}

export default connect(state => ({
  topColors: state.app.topColors,
  isSubmenuVisible: state.app.isSubmenuVisible,
}), null)(AboutUsPage)


export const query = graphql`
    query($remoteId: ID, $langKey:String) {
            seo: allCraftArcheeONasArcheeONasEntry(filter: {remoteId: {eq: $remoteId}, language: {eq: $langKey}}) {
                nodes{
                    ...seoAbout
                }
            }
            entries: allCraftArcheeONasArcheeONasEntry(filter: {remoteId: {eq: $remoteId}, language: {eq: $langKey}}) {
                nodes {
                    title,
                    archeeTopKolory,
                    archeeBaner {
                        ...banner,
                    }
                    archeeTrescPodstrony {
                        __typename
                        ...margines,
                        ...naglowek,
                        ...naglowekIOpis,
                        ...component,
                        ...zdjecieSzerokie,
                        ...filmLink,
                        ...przycisk
                        ...modulyGraficzne
                    }
                }
            }
    }`

export function Head({ data }) {
    const seoData = data?.seo?.nodes?.[0] || [];
    return (
        <SEO seoData={seoData}/>
    )
}